import { Component, Host, h, getAssetPath } from '@stencil/core';

@Component({
  tag: 'x-app',
  styleUrl: 'x-app.sass',
  assetsDirs: ['assets'],
})
export class XApp {
  render() {
    return (
      <Host>
        <style>{`
          @font-face {font-family: "SF Compact Display"; font-weight: 100; src: url("${getAssetPath('./assets/fonts/SFCompactDisplay-Thin.otf')}") format("opentype");}
          @font-face {font-family: "SF Compact Display"; font-weight: 400; src: url("${getAssetPath('./assets/fonts/SFCompactDisplay-Regular.otf')}") format("opentype");}
          @font-face {font-family: "SF Compact Display"; font-weight: 500; src: url("${getAssetPath('./assets/fonts/SFCompactDisplay-Medium.otf')}") format("opentype");}
          @font-face {font-family: "SF Compact Display"; font-weight: 600; src: url("${getAssetPath('./assets/fonts/SFCompactDisplay-Semibold.otf')}") format("opentype");}
          @font-face {font-family: "SF Compact Display"; font-weight: 700; src: url("${getAssetPath('./assets/fonts/SFCompactDisplay-Bold.otf')}") format("opentype");}
        `}</style>
        <slot></slot>
        <div class="air-datepicker-global-container"></div>
      </Host>
    );
  }
}
