x-app[theme="green-light"]
  --base-radius: 0px
  --primary: #00B177
  --primary-hover: #019871

x-app[theme="blue-light"]
  --base-radius: 4px
  --primary: #0091D9
  --primary-hover: #0A85C2

x-app[theme="orange-light"]
  --base-radius: 4px
  --bg-grey: #F5F5F5
  --button-disabled: #E5E5E5
  --button-hover-red: #B91818
  --button-static-dark-grey: #D2D4DC
  --button-static-grey: #E5E5E5
  --button-static-red: #E32727
  --check-radio-disabled: #F5F5F5
  --check-radio-static: #E5E5E5
  --divider-dark-grey: #E5E5E5
  --icon-dark-50: #6C6C70
  --icon-dark-grey: #A0A0AA
  --icon-red: #E32727
  --input-disabled-bg: #F5F5F5
  --input-disabled: #E5E5E5
  --input-error: #E32727
  --input-static: #E5E5E5
  --on-bg-dark: #0F0F11
  --on-button-dark-grey: #A0A0AA
  --on-button-dark: #0F0F11
  --on-input-dark-50: #6C6C70
  --on-input-white-20: #3F3F41
  --on-primary-dark-50: #878790
  --on-primary-dark: #0F0F11
  --on-surface-dark-50: #6C6C70
  --on-surface-dark: #0F0F11
  --on-surface-green: #1BAA4B
  --on-surface-red: #E32727
  --on-surface-yellow: #F5A623
  --primary-grey: #FAFAFA
  --primary-hover: #F07B03
  --primary: #FF7B00
  --surface-light-grey: #FAFAFA

x-app
  --bg-grey: #EFF1F4
  --button-active-light-grey: #FAFAFB
  --button-active-primary: var(--primary)
  --button-disabled: #EFF1F4
  --button-hover-green: var(--primary-hover)
  --button-hover-light-grey: #FAFAFB
  --button-hover-red: #B82424
  --button-static-dark-grey: #D2D4DC
  --button-static-green: var(--primary)
  --button-static-grey: #EFF1F4
  --button-static-primary: var(--primary)
  --button-static-red: #CC2727
  --button-static-white: #FFFFFF
  --button-yellow: #F5A623
  --check-radio-active: var(--primary)
  --check-radio-disabled: #EFF1F4
  --check-radio-static: #D2D4DC
  --check-radio-white: #FFFFFF
  --divider-dark-grey: #D2D4DC
  --icon-dark-50: #7D8F97
  --icon-dark-grey: #D2D4DC
  --icon-hover-primary: var(--primary-hover)
  --icon-primary: var(--primary)
  --icon-red: #CC2727
  --input-disabled-bg: #EFF1F4
  --input-disabled: #D2D4DC
  --input-error: #CC2727
  --input-focus: var(--primary)
  --input-static: #D2D4DC
  --input-white: #FFFFFF
  --on-bg-dark: #002534
  --on-button-active-green: var(--primary)
  --on-button-active-primary: var(--primary)
  --on-button-dark-grey: #D2D4DC
  --on-button-dark: #002534
  --on-button-hover-green: var(--primary-hover)
  --on-button-hover-primary: var(--primary-hover)
  --on-button-primary: var(--primary)
  --on-button-white: #FFFFFF
  --on-input-dark-50: #7D8F97
  --on-input-green: var(--primary)
  --on-input-hover-green: var(--primary-hover)
  --on-input-white-20: #33515D
  --on-primary-dark-50: #7D8F97
  --on-primary-dark: #002534
  --on-primary-green: var(--primary)
  --on-primary-red: #CC2727
  --on-surface-dark-50: #7D8F97
  --on-surface-dark: #002534
  --on-surface-green: #00B177
  --on-surface-red: #CC2727
  --on-surface-yellow: #F5A623
  --on-surface-white: #FFFFFF
  --primary-grey: #FAFAFB
  --primary-white: #FFFFFF
  --surface-light-grey: #FAFAFB
  --surface-shadow: 0px 4px 8px rgba(0, 0, 0, 0.0495)
  --surface-white: #FFFFFF
  --table-tag-amber: #FFF8E1
  --table-tag-blue-gray: #ECEFF1
  --table-tag-cyan: #E0F7FA
  --table-tag-focus-20: rgba(19, 33, 42, 0.2)
  --table-tag-indigo: #E8EAF6
  --table-tag-light-green: #F1F8E9
  --table-tag-pink: #FCE4EC

x-app
  display: block
  min-width: 1366px
  height: 100%
  background-color: var(--bg-grey)
